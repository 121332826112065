(function () {
  angular
    .module("akitabox.core.services.chart", [
      "akitabox.constants",
      "akitabox.core.lib.moment",
      "akitabox.core.services.organization",
      "akitabox.core.services.request",
      "akitabox.core.services.timeCode",
      "akitabox.core.services.user",
      "akitabox.core.services.workOrder",
      "akitabox.core.services.workOrderLog",
      "akitabox.core.utils",
    ])
    .factory("ChartService", ChartService);

  /** @ngInject */
  function ChartService(
    // Angular
    $q,
    // Constants
    models,
    SOURCES,
    // Libraries
    moment,
    // Services
    OrganizationService,
    RequestService,
    TimeCodeService,
    UserService,
    Utils,
    WorkOrderService,
    WorkOrderLogService
  ) {
    var service = {
      // Constants
      CHART_TYPE_BAR: "bar",
      CHART_TYPE_LINE: "line",
      // General
      getChartConfig: getChartConfig,
      addEmptyBars: addEmptyBars,
      isBarDataEmpty: isBarDataEmpty,
      isLineDataEmpty: isLineDataEmpty,
      shouldShowChart: shouldShowChart,
      normalize: normalize,
      // Y-Axis
      getYAxisConfig: getYAxisConfig,
      // Handling dates/date-based data
      buildDateString: buildDateString,
      buildTimeDifference: buildTimeDifference,
      getGroupTime: getGroupTime,
      buildDateArray: buildDateArray,
      parseDateResults: parseDateResults,
      getDateFilterConfig: getDateFilterConfig,
      // Stacking
      getStackOptions: getStackOptions,
      parseStackData: parseStackData,
      updateStackFilter: updateStackFilter,
      updateFiltersWithLookupModel: updateFiltersWithLookupModel,
      // Line charts
      toggleLineVisibility: toggleLineVisibility,
      // Time Codes
      getTimeCodes: getTimeCodes,
      filterTimeCodeLabel: filterTimeCodeLabel,
      transformTimeCodeData: transformTimeCodeData,
      // Misc
      sortAge: sortAge,
      sortIntent: sortIntent,
      updateIdentityDisplayNames: updateIdentityDisplayNames,
    };

    // Constants
    var NO_STACK_OPTION = "---";

    // Work Order Constants
    var DEFAULT_WORK_ORDER_STACK_OPTIONS = [
      "Assignee",
      "Work Performed By",
      "Time Code",
      "Priority",
      "Trade",
      "Type",
      "Reactive / Preventive",
      "Source",
    ];

    var DEFAULT_WORK_ORDER_Y_AXIS_OPTION = "# Completed";
    // Service Request Constants
    var DEFAULT_SERVICE_REQUEST_STACK_OPTIONS = ["Type"];

    // fields that should not have a `lookup_field` prepended when stacking
    var badPrependFields = [
      "identity.email",
      "identity._id",
      "building",
      "time_code",
    ];
    // This variable will hold the timeCodes, because both(filterTimeCodeLabel and transformTimeCodeData) functions use this timeCodes and are called one after another
    var timeCodes;

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Default transform, does nothing
     *
     * @param {Array|Number} data
     */
    function defaultTransform(data) {
      return data;
    }

    /**
     * Transform minutes into hours
     *
     * @param {Array|Number} data
     */
    function hoursLoggedTransform(data) {
      if (angular.isNumber(data)) {
        return data / 60;
      }
      Object.keys(data).forEach(function (key) {
        data[key].result = data[key].result / 60;
      });
      return data;
    }

    /**
     * Transforms milliseconds to days
     *
     * @param {Array|Number} data
     */
    function durationTransform(data) {
      if (angular.isNumber(data)) {
        var days = moment.duration(data).asDays();
        return days;
      } else if (angular.isArray(data)) {
        return data.map(function (stat) {
          var days = moment.duration(stat.result).asDays();
          stat.result = days;
          return stat;
        });
      }
      return data;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Builds config for chart components
     *
     * @param {Array} buildings
     * @param {String} modelName
     */
    function getChartConfig(buildings, modelName) {
      var firstBuilding = buildings[0];

      var service = getService(modelName);
      if (buildings.length === 1) {
        return {
          parentId: firstBuilding._id,
          statsFunction: service.getStatsByBuilding,
        };
      }
      var buildingMap = buildings.reduce(function (map, building) {
        map[building._id] = building.name;
        return map;
      }, {});
      return {
        parentId: firstBuilding.organization,
        statsFunction: service.getStatsByOrganization,
        buildingMap: buildingMap,
        buildingInString:
          "$in," +
          buildings
            .map(function (building) {
              return building._id;
            })
            .join(","),
      };
    }

    /**
     * Get the corresponding service for the provided model
     *
     * @param {*} modelName
     */
    function getService(modelName) {
      switch (modelName) {
        case models.WORK_ORDER_LOG.MODEL:
          return WorkOrderLogService;
        case models.WORK_ORDER.MODEL:
          return WorkOrderService;
        case models.SERVICE_REQUEST.MODEL:
          return RequestService;
      }
    }

    /**
     * Add empty bars to data based on filters user has chosen
     *
     * @param {Array} data parsed data that's ready for charts
     * @param {String} filters filters to show empty bars from (an $in value)
     */
    function addEmptyBars(data, filters) {
      var existingValues = {};
      if (!filters) return;
      var indexToRemove = -1;

      // Find a real key from the data to use
      var d = Object.assign({}, data[0]);
      delete d._id;
      var key = Object.keys(d)[0];

      for (var i = 0; i < data.length; ++i) {
        var dataValue = data[i]._id;

        if (!dataValue) {
          indexToRemove = i;
          break;
        }
        if (existingValues[dataValue]) {
          continue;
        }

        existingValues[dataValue] = 1;
      }
      if (indexToRemove > -1) {
        data.splice(indexToRemove);
      }

      var filteredValues = filters.split(",");
      for (var j = 0; j < filteredValues.length; ++j) {
        var filteredValue = filteredValues[j];
        if (filteredValue === "$in" || existingValues[filteredValue]) {
          continue;
        }

        var r = { _id: filteredValue };
        r[key] = 0;
        data.push(r);
      }
    }

    /**
     * Determine if bar chart data is empty (zeros)
     *
     * @param {Object[]} data   Bar chart data
     *
     * @returns {Boolean} True if empty, false if not
     */
    function isBarDataEmpty(data) {
      var empty = true;
      for (var i = 0; i < data.length; ++i) {
        var point = data[i];
        if (point) {
          if (point.result > 0) {
            empty = false;
            break;
          } else {
            // Check stack data
            if (!empty) {
              break;
            }
            var keys = Object.keys(point);
            for (var j = 0; j < keys.length; ++j) {
              var stackKey = keys[j];
              if (stackKey !== "date" && point[stackKey] > 0) {
                empty = false;
                break;
              }
            }
          }
        }
      }
      return empty;
    }

    /**
     * Determine if line chart data is empty (zeros)
     * The data is considered empty if all lines are empty
     *
     * @param {Object[]} lines  Line chart data
     *
     * @returns {Boolean} True if empty, false if not
     */
    function isLineDataEmpty(lines) {
      var empty = true;
      for (var i = 0; i < lines.length; ++i) {
        var line = lines[i];
        if (!empty) {
          break;
        }
        for (var j = 0; j < line.length; ++j) {
          var point = line[j];
          if (point && point.y > 0) {
            empty = false;
            break;
          }
        }
      }
      return empty;
    }

    /**
     * Check chart dates and data based on model, type, and y-axis option
     *
     * Chart should NOT be shown if viewing default options and there is no data
     * Chart should be shown if NOT viewing default options and there is no data
     *
     * @param {String}    modelName           Model name (constant)
     * @param {String}    type                Chart type
     * @param {Object[]}  data                Chart data
     * @param {Object}    options             Chart options
     * @param {Date}      options.startDate   Start of date range
     * @param {Date}      options.endDate     End of date range
     * @param {String}    options.yAxis       Selected y-axis option
     */
    function shouldShowChart(modelName, type, data, options) {
      var startDate = options.startDate;
      var endDate = options.endDate;
      var yAxis = options.yAxis;

      var validDates = Boolean(startDate) && Boolean(endDate);

      if (!validDates) {
        return false;
      }

      var checkData = true;
      var hasData = false;

      switch (modelName) {
        case models.WORK_ORDER.MODEL:
          // Check data if y-axis hasn't changed
          checkData = !yAxis || yAxis === DEFAULT_WORK_ORDER_Y_AXIS_OPTION;
          break;
        case models.SERVICE_REQUEST.MODEL:
          checkData = true;
          break;
      }

      if (checkData) {
        switch (type) {
          case service.CHART_TYPE_BAR:
            hasData = !service.isBarDataEmpty(data);
            break;
          case service.CHART_TYPE_LINE:
            hasData = !service.isLineDataEmpty(data);
            break;
          default:
            throw new Error("Invalid chart type");
        }
      } else {
        hasData = true;
      }

      return hasData;
    }

    /**
     * Takes a string and makes it a valid html #id
     *
     * @param {String} s  String to normalize
     *
     * @return {String} Valid html #id
     */
    function normalize(s) {
      return "abx-" + Utils.hashCode(s);
    }

    /**
     * Constructs the y-axis options for the chart given the desired options and current date range
     *
     * @param {Object} options
     * @param {Date} startDate
     * @param {Date} endDate
     */
    function getYAxisConfig(options, startDate, endDate) {
      var completedDateString = buildDateString(startDate, endDate);
      var today = new moment().utc().endOf("day").toDate();

      var openEndDate = endDate && today > endDate ? endDate : today;
      var openDateString = buildDateString(startDate, openEndDate);

      var scheduledStartDate =
        startDate && today < startDate ? startDate : today;
      var scheduledDateString = buildDateString(scheduledStartDate, endDate);

      var yAxisFilterOptions = {
        "# Completed": {
          params: {
            status: "completed",
            completed_date: completedDateString,
            operator: "count",
          },
          transform: defaultTransform,
          label: "Completed",
        },
        "# Open": {
          params: {
            status: "open",
            start_date: openDateString,
            operator: "count",
          },
          transform: defaultTransform,
          label: "Open",
        },
        "# Overdue": {
          params: {
            status: "open",
            start_date: openDateString,
            due_date: buildDateString(null, today),
            operator: "count",
          },
          transform: defaultTransform,
          label: "Overdue",
        },
        "# Scheduled": {
          params: {
            status: "open",
            start_date: scheduledDateString,
          },
          transform: defaultTransform,
          label: "Scheduled",
        },
        "Avg. Duration (Days)": {
          params: {
            completed_date: completedDateString,
            operator: "avg",
            projection_operator: "subtract",
            projection_fields: "completed_date,start_date",
          },
          transform: durationTransform,
          label: "Days",
        },
        // eslint-disable-next-line prettier/prettier
        Cost: {
          params: {
            work_performed_date: completedDateString,
            operator: "sum",
            operator_field: "cost",
          },
          lookupModel: models.WORK_ORDER.MODEL,
          overrideModel: models.WORK_ORDER_LOG.MODEL,
          transform: defaultTransform,
          label: "Cost",
        },
        "Hours Logged": {
          params: {
            work_performed_date: completedDateString,
            operator: "sum",
            operator_field: "work_minutes",
          },
          lookupModel: models.WORK_ORDER.MODEL,
          overrideModel: models.WORK_ORDER_LOG.MODEL,
          // transform: defaultTransform,
          transform: hoursLoggedTransform,
          label: "Hours Logged",
        },
      };

      var config = {};
      for (var i = 0; i < options.length; ++i) {
        var currentOption = options[i];
        config[currentOption] = yAxisFilterOptions[currentOption];
      }

      return config;
    }

    function buildDateString(startDate, endDate) {
      var gte = "";
      var lte = "";
      if (
        (startDate && startDate.toString() === "Invalid Date") ||
        (endDate && endDate.toString() === "Invalid Date")
      ) {
        return null;
      } else if (startDate && endDate) {
        gte = "$gte," + startDate.valueOf();
        lte = "$lte," + endDate.valueOf();
        return lte + "," + gte;
      } else if (startDate) {
        gte = "$gte," + startDate.valueOf();
        return gte;
      } else if (endDate) {
        lte = "$lte," + endDate.valueOf();
        return lte;
      } else {
        return null; // both values passed in are null;
      }
    }

    /**
     * Takes in two dates and returns a config containing the difference in years and days between the two dates
     *
     * @param {Date} start
     * @param {Date} end
     */
    function buildTimeDifference(start, end) {
      var startDate = moment(new Date(start.valueOf()));
      var endDate = moment(new Date(end.valueOf()));
      return {
        years: endDate.diff(startDate, "years"),
        months: endDate.diff(startDate, "months"),
        days: endDate.diff(startDate, "days"),
      };
    }

    /**
     * Given two dates, returns a group_time string depending on the distance between the two dates
     *
     * @param {Date} startDate
     * @param {Date} endDate
     */
    function getGroupTime(startDate, endDate) {
      var timeDifference = buildTimeDifference(startDate, endDate);
      var groupTime = "day_of_month,month,year";
      if (timeDifference.days >= 60) groupTime = "month,year";
      if (timeDifference.years >= 3) groupTime = "year";
      return groupTime;
    }

    /**
     * Builds an array of Dates to be used in time based charts.
     */
    function buildDateArray(start, end, keys) {
      var xKey = keys ? keys[0] : "x";
      var yKey = keys ? keys[1] : "y";
      var dateContainer = {
        array: [],
        indexMap: {},
      };
      var timeDifference = buildTimeDifference(start, end);

      var startDate = moment(new Date(start.valueOf()));
      var endDate = moment(new Date(end.valueOf()));
      var date = startDate;
      var offset = "days";
      var index = 0;

      if (timeDifference.days >= 60) {
        offset = "months";
        date.date(1);
      }
      if (timeDifference.years >= 3) {
        offset = "years";
        date.date(1);
      }

      for (date; date.isSameOrBefore(endDate); date.add(1, offset)) {
        dateContainer.indexMap[date.toDate().valueOf()] = index;
        var dateObject = {};
        dateObject[xKey] = date.toDate();
        dateObject[yKey] = 0;
        dateContainer.array.push(dateObject);
        index++;
      }

      return dateContainer;
    }

    /**
     * parses date based stats results into something usable by charts
     */
    function parseDateResults(results, dates, transform) {
      var stats = dates ? dates.array : [];
      for (var i = 0; i < results.length; i++) {
        var result = results[i];
        var date = result._id;

        if (!date || !date.year) continue;

        var month = date.month ? date.month - 1 : 0;
        var day = date.day_of_month ? date.day_of_month : 1;

        date = new Date(date.year, month, day);
        var stat;
        var index = dates.indexMap[date.valueOf()];
        if (index >= 0) {
          stat = stats[index];
          var lines = stat.y > -1;
          var bars = stat.result > -1;
          var stacks = stat.results > -1;
          if (lines) {
            stat.y = transform ? transform(result.result) : result.result;
          } else if (bars) {
            stat.result = transform ? transform(result.result) : result.result;
          } else if (stacks) {
            stat.results = result.results;
          }
        }
      }
      return stats;
    }

    /**
     * Returns default options for date ranges to filter by
     */
    function getDateFilterConfig(includeFuture) {
      var today = moment().endOf("day").toDate();
      var tomorrow = moment().add(1, "days").startOf("day").toDate();
      var options = [
        "Last 7 Days",
        "Last 30 Days",
        "Previous Month",
        "Month to Date",
        "Year to Date",
        "Last Year",
      ];
      if (includeFuture) {
        options.splice(1, 0, "Next 7 Days");
        options.splice(3, 0, "Next 30 Days");
      }
      var map = {
        "Last 7 Days": {
          startDate: moment().subtract(7, "days").startOf("day").toDate(),
          endDate: today,
        },
        "Next 7 Days": {
          startDate: tomorrow,
          endDate: moment().add(7, "days").endOf("day").toDate(),
        },
        "Last 30 Days": {
          startDate: moment().subtract(30, "days").startOf("day").toDate(),
          endDate: today,
        },
        "Next 30 Days": {
          startDate: tomorrow,
          endDate: moment().add(31, "days").endOf("day").toDate(),
        },
        "Previous Month": {
          startDate: moment().subtract(1, "months").startOf("month").toDate(),
          endDate: moment().subtract(1, "months").endOf("month").toDate(),
        },
        "Month to Date": {
          startDate: moment().startOf("month").toDate(),
          endDate: today,
        },
        "Year to Date": {
          startDate: moment().startOf("year").toDate(),
          endDate: today,
        },
        "Last Year": {
          startDate: moment().subtract(1, "years").startOf("year").toDate(),
          endDate: moment().subtract(1, "years").endOf("year").toDate(),
        },
      };

      return { options: options, map: map };
    }

    /**
     * Returns a list of options to stack by based on model, buildings,
     * and any additional options the user provides
     *
     * @param {String}  model               Model name (eg. Work Order)
     * @param {Boolean} multipleBuildings   Add "Building" option
     * @param {Array}   options             Custom options to add
     * @param {Array}   exclude             Options to exclude
     */
    function getStackOptions(model, multipleBuildings, options, exclude) {
      var stackOptions =
        model === models.WORK_ORDER.MODEL
          ? DEFAULT_WORK_ORDER_STACK_OPTIONS.slice()
          : DEFAULT_SERVICE_REQUEST_STACK_OPTIONS.slice();
      if (multipleBuildings) {
        stackOptions.push("Building");
      }
      if (options) {
        if (Array.isArray(options)) {
          stackOptions = stackOptions.concat(options);
        } else {
          stackOptions.push.apply(options);
        }
      }
      stackOptions.unshift(NO_STACK_OPTION);
      if (exclude && exclude.length) {
        var difference = [];
        for (var i = 0; i < stackOptions.length; ++i) {
          var opt = stackOptions[i];
          if (exclude.indexOf(opt) === -1) {
            difference.push(opt);
          }
        }
        stackOptions = difference;
      }
      return stackOptions;
    }

    /**
     * Parses stack data from the API into something d3 charts can read
     *
     * @param {Array} dataFromAPI
     * @param {String} stackField
     * @param {Array} map
     * @param {Function} transform
     * @param {Array<Building>} buildings
     * @return {Promise<{ data: object, keys: Array<string> }>}
     */
    function parseStackData(
      dataFromAPI,
      stackField,
      map,
      transform,
      buildings
    ) {
      if (!buildings) {
        buildings = [];
      }

      var stackKeys = {}; // Will store the "template" for a given stacked bar
      var stackData = dataFromAPI.map(function (group) {
        var stack = {}; // Will store the data for each stacked bar
        // iterate through each data point and flatten into an object of key: _id and value: result
        var length = group.results ? group.results.length : 0;
        for (var i = 0; i < length; ++i) {
          var result = group.results[i];

          var key = result._id ? result._id : "None";
          if (map) {
            key = map[key]; // handles case were _id are actual _ids, need to convert to name for user
          } else if (
            stackField &&
            (stackField.toLowerCase() === "source" ||
              stackField.toLowerCase() === "task.source")
          ) {
            key = SOURCES[key];
          }

          var value = result.result;

          // if value = 0, don't create a stack option, otherwise an error will be thrown
          // when we try to calculate the height of the stack area in d3
          if (value && value > 0) {
            stackKeys[key] = 0; // Build the template as we loop through the data
            stack[key] = transform ? transform(value) : value; // transform into format y-axis wants
          }
        }
        stack = angular.extend(group, stack); // merge with group to get the "date" or "_id" that will be on the x-axis
        delete stack.results; // no need for array of results now that it's been put on the object in key/value pairs
        return stack;
      });
      // chart needs all keys to be on each stack, even if the value is 0, so we need to loop through again
      var chartData = stackData.map(function (stack) {
        return angular.extend({}, stackKeys, stack);
      });
      // return data for chart, and keys for legend

      // Anything that is stacked by this type of field needs this special treatment to work
      if (stackField && stackField.toLowerCase() === "identity._id") {
        // Create api calls to get each user's identity (all the user's that will show up in the chart)
        var identityPromises = Object.keys(stackKeys).map(function (username) {
          return getIdentityDisplayName(username, buildings);
        });

        return $q.all(identityPromises).then(function (identities) {
          /**
           * Fn to convert the array of identities into an object of identities
           * [ { _id: "user_name", display_name: "Username" }, { _id: "bob_dole", display_name: "Bob Dole" }]
           * | |  | |
           * V V  V V
           * {{ user_name: "Username" }, { bob_dole: "Bob Dole" }}
           */
          var identitiesToObjects = function (identities) {
            return identities.reduce(function (obj, item) {
              obj[item._id] = item.display_name;
              return obj;
            }, {});
          };

          // Convert it here
          identities = identitiesToObjects(identities);

          /**
           * We need to make sure the chart data's keys map directly to the user's display_name, or else we can't hover
           * over the user's name in the lower portion of the graph to highlight the values.  The chart's data originally
           * comes in as the user._id, we need to change it to user.display_name
           * @example [{ user_name: 1, user_name_2: 2 }]
           */
          var identityChartData = chartData.map(function (data) {
            /**
             * This new object will be the exact same as the old chartData obj
             * just that its keys will be the user's dsplay_name instead of its _id
             * @example { bob_dole: 1 } ==> { "Bob Dole": 1 }
             */
            var displayNameData = {};

            for (var key in data) {
              if (identities[key]) {
                /**
                 * Create a new prop that matches the old charData prop, but with the display_name from the
                 * identities obj
                 */
                displayNameData[identities[key]] = data[key];
              } else {
                // If the prop's key isn't a user, we don't touch it
                displayNameData[key] = data[key];
              }
            }
            return displayNameData;
          });
          /**
           * We also need to make sure the keys sent back is an array of user's display_name
           * @example ["Bob Dole", "Username"]
           */
          var identityKeys = [];
          for (var key in identities) {
            identityKeys.push(identities[key]);
          }
          return {
            data: identityChartData,
            keys: identityKeys.sort(),
          };
        });
      } else if (stackField && stackField === "age_group") {
        /**
         * We do this to remove the `_` and lower case `days`
         * for a better user experience, eg: `<1_days` -> `<1 Day`
         */
        let readableAgeKeys = [];
        let readableAgeData = chartData.map((data) => {
          const readableAgeData = {};
          for (let key in data) {
            let readableAgeKey = "";
            if (key === "<1_days") {
              readableAgeKey = "<1 Day";
            } else if (key === "1-7_days") {
              readableAgeKey = "1-7 Days";
            } else if (key === "7-14_days") {
              readableAgeKey = "7-14 Days";
            } else if (key === "14-30_days") {
              readableAgeKey = "14-30 Days";
            } else if (key === ">30_days") {
              readableAgeKey = ">30 Days";
            } else {
              readableAgeKey = key;
            }
            if (
              !readableAgeKeys.includes(readableAgeKey) &&
              readableAgeKey.includes("Day")
            ) {
              readableAgeKeys.push(readableAgeKey);
            }
            readableAgeData[readableAgeKey] = data[key];
          }

          return readableAgeData;
        });

        return $q.resolve({
          data: readableAgeData,
          keys: readableAgeKeys,
        });
      } else {
        return $q.resolve({
          data: chartData,
          keys: Object.keys(stackKeys).sort(),
        });
      }
    }

    /**
     * Get Time codes to use them in the functions filters 'filterTimeCodeLabel' and 'transformTimeCodeData'
     */
    function getTimeCodes() {
      var organization = OrganizationService.getCurrent();

      return TimeCodeService.getAll(organization._id)
        .then((result) => {
          timeCodes = result;
        })
        .catch(() => {
          timeCodes = [];
        });
    }

    /**
     *
     * @param {string} identityId
     * @param {Array<Building>} buildings
     */
    function getIdentityDisplayName(identityId, buildings) {
      if (identityId === "None") {
        return $q.resolve({ display_name: "None", _id: "None" });
      }
      var organization = OrganizationService.getCurrent();
      return UserService.getByIdentity(organization._id, identityId).then(
        function (user) {
          return {
            _id: identityId,
            display_name: user.identity.display_name,
          };
        }
      );
    }

    /**
     * given an array of reporting results returned from the API,
     * convert each identity _id to the identity's display_name
     * @param {Array<{ _id: string, result: number }>} results = Array of reporting results in the format [{ _id: "bob_dole", result: 5 }, { _id: "jean_dole", result: 5 }]
     * @return {Array<{ _id: string, result: number }>} Returns an updated array in the format [{ _id: "Bob Dole", result: 5 }, { _id: "Jean Dole", result: 5 }]
     */
    function updateIdentityDisplayNames(results, buildings) {
      var organization = OrganizationService.getCurrent();
      return UserService.getAll(organization._id, {
        identity: "$ne,null",
      }).then(function (users) {
        // Create map of identity IDs to identities
        var identities = users.reduce(function (map, user) {
          var identity = user.identity;
          map[identity._id] = identity;
          return map;
        }, {});
        // Set result._id to user's name (identity.display_name)
        return results.map(function (result) {
          if (result._id === null) return result;
          var identity = identities[result._id];
          if (identity) {
            result._id = identity.display_name;
          }
          return result;
        });
      });
    }
    /**
     * Update the filters to include stack_field if provided. Additionally,
     * update filters to add/remove the lookupModel
     *
     * @param {String} value        string value of the stack filter
     * @param {Object} filters      object containing filters
     * @param {String} lookupModel  model name to prepend to filters
     */
    function updateStackFilter(value, filters, lookupModel) {
      // reset filter lookup, will get new value from the passed in lookupModel
      if (filters && filters.lookup_field) {
        delete filters.lookup_field;
      }
      // when hitting WO Logs route, if filtering for a Work Performed By User,
      // update filter to hit work_order_log.work_performed_by field instead of task.workers
      if (lookupModel === "task" && filters && filters.workers) {
        filters.work_performed_by = filters.workers;
        // otherwise reset the work_performed_by filter
      } else if (filters && filters.work_performed_by) {
        delete filters.work_performed_by;
      } else if (filters && filters.work_performed_by_user) {
        delete filters.work_performed_by_user;
      }

      var stackField;
      var unwindField;
      var unwindLookup;
      var lookupField;
      var groupByWorkers = lookupModel === "task";

      value = value.toLowerCase();

      switch (value) {
        case "---":
          stackField = null;
          break;
        case "assignee":
          stackField = "user._id";
          unwindField = "assignee_users"; // MWA allows use to use this new prop
          unwindLookup = true;
          break;
        case "trade":
          stackField = "trade_name";
          break;
        case "type":
          stackField = "type_name";
          break;
        case "reactive / preventive":
          stackField = "intent";
          break;
        case "work performed by":
          stackField = "identity._id";
          unwindField = "workers";
          unwindLookup = true;

          if (groupByWorkers) {
            stackField = "identity._id";
            lookupField = [lookupModel, "work_performed_by"];
            unwindLookup = false;
          }

          break;
        case "time code":
          stackField = "time_code";
          break;
        case "age":
          stackField = "age_group";
          break;
        default:
          stackField = value;
      }

      if (filters) {
        updateFiltersWithLookupModel(filters, lookupModel);
      }

      // can short-circuit before the additional checks if everything is null
      if (
        !stackField &&
        !unwindField &&
        !unwindLookup &&
        !lookupField &&
        !lookupModel &&
        !filters
      ) {
        return {};
      }

      if (
        lookupModel &&
        stackField &&
        badPrependFields.indexOf(stackField) === -1
      ) {
        stackField = lookupModel + "." + stackField;
      }

      if (lookupModel && unwindField) {
        unwindField = lookupModel + "." + unwindField;
      }

      var filter = angular.extend({}, filters, {
        stack_field: stackField,
        unwind_field: unwindField,
        unwind_lookup: unwindLookup,
        lookup_field: lookupField || lookupModel, // if lookupField is not set, default to the passed in model
      });

      // delete any undefined properties so they don't override
      // filters when we angular.extend() in the charts
      Object.keys(filter).forEach(function (key) {
        if (filter[key] === undefined) {
          delete filter[key];
        }
      });

      return filter;
    }

    /**
     * Adds or removes the lookupModel name to applied filters
     *
     * @param {Object} filters
     * @param {String} lookupModel
     */
    function updateFiltersWithLookupModel(filters, lookupModel) {
      // filter keys that should not have a lookupModel prepended
      var badPrependKeys = [
        "stack_field",
        "lookup_field",
        "workers",
        "work_performed_by",
      ];

      Object.keys(filters).forEach(function (key) {
        var parsedKey = key.split(".");
        // if a lookupModel is provided and it has not already been prepended to a valid
        // filters key, update the filters object with the new key
        if (
          lookupModel &&
          Object.prototype.hasOwnProperty.call(filters, key) &&
          parsedKey.length === 1 &&
          badPrependKeys.indexOf(key) === -1
        ) {
          filters[lookupModel + "." + key] = filters[key];
          delete filters[key];
          // otherwise, if no lookupModel is provided, update the filters
          // to remove the lookupModel from exisitng keys
        } else if (
          !lookupModel &&
          Object.prototype.hasOwnProperty.call(filters, key) &&
          parsedKey.length > 1
        ) {
          filters[parsedKey[1]] = filters[key];
          delete filters[key];
        }
      });
    }

    function toggleLineVisibility(index, lines, visibleData) {
      var numVisibleLines = 0;
      var statusConfig = lines[index];
      var newVisibility = !statusConfig.visible;
      for (var i = 0; i < lines.length; ++i) {
        if (lines[i].visible) {
          numVisibleLines++;
        }
      }
      if (numVisibleLines > 1 || newVisibility) {
        statusConfig.visible = newVisibility;
        visibleData[index] = newVisibility;
      }
      return { visibleData: visibleData, lines: lines };
    }

    /**
     * Custom sort function to sort reactive before preventive
     */
    function sortIntent(a) {
      if (a.toLowerCase() === "reactive") {
        return -1;
      }
      return 1;
    }

    function sortAge(a, b) {
      if (a === "<1 Day") {
        // make sure we always put a BEFORE b if a is <1_day
        return -1;
      } else if (b === "<1 Day") {
        // make sure we always put a AFTER b if b is <1_day
        return 1;
      } else if (a === ">30 Days") {
        // make sure we always put a AFTER b if a is >30_days
        return 1;
      } else if (b === ">30 Days") {
        // make sure we always put a BEFORE b if b is >30_days
        return -1;
      } else if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }

      return 0;
    }

    function filterTimeCodeLabel(timeCodesIds) {
      var timeCodeLabels = [];
      for (const timeCodeId of timeCodesIds) {
        var timeCodeLabel;
        if (timeCodeId === "None") {
          timeCodeLabel = "None";
        } else {
          var timeCodeFound = timeCodes.find(
            (timeCode) => timeCode._id === timeCodeId
          );
          timeCodeLabel = `${timeCodeFound.code} - ${timeCodeFound.name}`;
        }
        timeCodeLabels.push(timeCodeLabel);
      }
      return timeCodeLabels;
    }

    function transformTimeCodeData(timeCodeData) {
      var result = [];
      for (const data of timeCodeData) {
        var newData = {};
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            var timeCodeFound = timeCodes.find(
              (timeCode) => timeCode._id === key
            );
            if (timeCodeFound) {
              var propertyName = `${timeCodeFound.code} - ${timeCodeFound.name}`;
              newData[propertyName] = element;
            } else {
              newData[key] = element;
            }
          }
        }
        result.push(newData);
      }
      return result;
    }
  }
})();
